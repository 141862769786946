import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['counter'];

    connect() {
        this.update();
    }

    update() {
        const checksCount = this.element.querySelectorAll('input:checked').length;
        let selectCount = 0;

        const criteriaSetInput = this.element.querySelector('#filters_criteriaSet');

        if (criteriaSetInput?.[criteriaSetInput.selectedIndex].text != 'Indifférent') {
            selectCount = this.element.querySelectorAll('option:checked').length;
        }

        this.counterTarget.innerText = checksCount + selectCount;
    }

    reset() {
        this.counterTarget.innerText = 0;
    }
}
