import { Controller } from '@hotwired/stimulus';

import ApiClient from '../../smart-content/ApiClient';

export default class extends Controller {
    static targets = ['recommendations'];

    static values = {
        widgetIds: Array,
    };

    connect() {
        this.isInitialized = false;
        this.boundInit = this.init.bind(this);

        document.addEventListener('userSessionUpdated', this.boundInit);

        this.init();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundInit);
    }

    init() {
        if (this.isInitialized) {
            return;
        }

        this.updateView();
        this.isInitialized = true;
    }

    updateView() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const selligentUserId = user?.content?.user.mailing_router_id;

        new ApiClient(selligentUserId, this.widgetIdsValue)
            .getRecommendations()
            .then((recommendations) => {
                if (recommendations.length > 0) {
                    this.recommendationsTarget.innerHTML = '';

                    recommendations.forEach((data) => this.renderArticle(data));
                }
            });
    }

    renderArticle(data) {
        this.recommendationsTarget.innerHTML += this.getContent(data);
    }

    getContent(item) {
        return `
            <article class="lg:tw-mr-0 lg:tw-mb-6">
                <a href="${item.tracking_url}" class="${this.getClassItem(item)}">
                    ${this.getCardContent(item)}
                    <div class="tw-relative tw-h-[220px] tw-px-4 tw-py-6 tw-mr-2 ${this.getClassContent(item)}">
                        <div class="tw-uppercase tw-mb-2 tw-font-heading tw-font-medium ${this.getClassTitle(item)}">
                            ${this.trimLeadingQuotes(item.city || this.getCategory(item))}
                        </div>
                        <h3 
                            class="tw-block tw-text-black tw-font-heading tw-text-baseBis tw-leading-4 tw-mb-6
                                tw-whitespace-normal tw-line-clamp-3"
                        >
                            ${this.getTitle(item)}
                        </h3>
                    </div>
                    ${this.getNoteContent(item)}
                </a>
            </article>`;
    }

    getTitle(item) {
        return this.trimLeadingQuotes(item.title);
    }

    getCategory(item) {
        if (item.type === 'fme_sub_activity_area') {
            return 'Secteurs métiers';
        }

        if (item.type === 'fet_study_card') {
            return "Domaines d'études";
        }

        if (item.type === 'clt_ranking') {
            return 'Classements';
        }

        if (item.type === 'osp_card') {
            return '';
        }

        return item.category;
    }

    getCardContent(item) {
        const classes = `tw-h-[220px] tw-flex tw-justify-center tw-items-center tw-rounded-l-large tw-border-t-2 tw-border-l-2
        ${item.color ? 'tw-border-r-0' : 'tw-border-r-2'} tw-border-b-2 tw-border-gray-400`;

        if (item.type === 'osp_card') {
            return `
                <div class="${classes}" ${item.color ? `style="background-color: ${item.color}"` : ''}>
                    <figure class="tw-p-2">
                        <img
                            class="${this.getClassImg(item)}"
                            data-src="${item.image_url}"
                            src="${item.image_url}"
                        >
                    </figure>
                </div>
            `;
        }

        return '';
    }

    getClassImg(item) {
        if (item.type === 'osp_card') {
            return 'tw-min-w-[65px] tw-max-w-[65px] tw-min-h-[65px] tw-max-h-[65px] tw-rounded-full';
        }

        return 'tw-object-cover tw-w-full tw-h-[112px] sm:tw-h-[142px] tw-rounded-large';
    }

    getClassContent(item) {
        if (item.type === 'osp_card') {
            return 'tw-w-[200px] tw-rounded-r-large tw-border-t-2 tw-border-r-2 tw-border-b-2 tw-border-gray-400';
        }

        return 'tw-w-[280px] tw-rounded-large tw-border-2 tw-border-gray-400';
    }

    getNoteContent(item) {
        if (item.sponsor !== '') {
            return `
                <div class="tw-text-sm tw-text-center">
                    Proposé par <span class="${this.getClassTitle(item)}">${item.sponsor}</span>
                </div>
            `;
        }

        return '';
    }

    getClassTitle(item) {
        if (item.type === 'sal_show') {
            return 'tw-text-green-700';
        }

        if (item.sponsor !== '') {
            return 'tw-text-piscine-800';
        }

        return 'tw-text-primary';
    }

    getClassItem(item) {
        if (item.type === 'osp_card') {
            return 'tw-flex tw-rounded-large tw-overflow-hidden tw-h-full';
        }

        return '';
    }

    dateContent(date) {
        const dateParts = new Date(this.trimLeadingQuotes(date)).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' }).split(' ');

        return `
            <time class="tw-block tw-font-heading tw-text-3xl tw-leading-7 tw-text-center" datetime="${date.split(' ')[0]}">
                ${dateParts[0]}
                <span class="tw-block tw-text-sm tw-leading-normalBis tw-uppercase">${dateParts[1]}</span>
            </time>
        `;
    }

    trimLeadingQuotes(content) {
        return content.replace(/^"/, '').replace(/"$/, '');
    }
}
