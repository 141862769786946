import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        scriptUrl: String,
    };

    connect() {
        const qiota = document.createElement('script');
        qiota.src = this.scriptUrlValue;
        (document.getElementsByTagName('head')[0]
            || document.getElementsByTagName('body')[0]
            || document.getElementsByTagName('script')[0].parentNode
        ).insertBefore(qiota, null);

        window.addEventListener('register-form-pre-submit', this.handleQiotaStats.bind(this));
    }

    disconnect() {
        window.removeEventListener('register-form-pre-submit', this.handleQiotaStats.bind(this));
    }

    handleQiotaStats() {
        window.q_token = 'wRHxIJOtBW';
        window.sendQiotaStats?.(window.q_token);
    }
}
