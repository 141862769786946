import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['firstStep', 'secondStep', 'form'];

    showSecondStep() {
        this.firstStepTarget.classList.add('tw-hidden');
        this.secondStepTarget.classList.remove('tw-hidden');

        this.element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    backToFirstStep() {
        this.secondStepTarget.classList.add('tw-hidden');
        this.firstStepTarget.classList.remove('tw-hidden');

        this.element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    handleSubmit() {
        window.dispatchEvent(new CustomEvent('register-form-pre-submit'));
        this.formTarget.submit();
    }
}
