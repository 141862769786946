import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['container'];

    connect() {
        this.selects = [];
    }

    disconnect() {
        this.selects.forEach((select) => {
            select.removeEventListener('change', this.onSelectChange);
        });
    }

    watchSelect(event) {
        if (!this.hasContainerTarget) {
            return;
        }

        const select = event.detail.selectElement;
        if (!select || select.getAttribute('data-search-tag-aggregate') !== 'true') {
            return;
        }

        this.selects.push(select);
        select.addEventListener('change', this.computeContainerTags.bind(this));

        this.computeContainerTags();
    }

    computeContainerTags() {
        const content = [];

        this.selects.forEach((select) => {
            Array.from(select.selectedOptions).forEach((option) => {
                const { parentElement } = option;
                const parentGroup = parentElement.tagName === 'OPTGROUP' ? parentElement : null;

                const label = option.label + (parentGroup ? ` - ${parentGroup.label}` : '');
                content.push(`
                    <li class="tw-flex tw-justify-between tw-mb-4 tw-items-baseline tw-min-w-auto tw-mr-2 tw-px-4 
                            tw-py-[5px] tw-border-solid tw-border tw-border-gray-600 tw-rounded-full tw-bg-white">
                        <span class="tw-block tw-font-heading tw-whitespace-no-wrap">${label}</span>
                        <i data-action="click->search-tag-aggregator#removeTag"
                            data-search-tag-aggregator-value-param="${option.value}"
                            data-search-tag-aggregator-label-param="${option.label}"
                            data-search-tag-aggregator-select-id-param="${select.id}"
                            class="fas fa-times tw-relative tw-cursor-pointer tw-text-xl tw-text-gray-700 tw-ml-[6px]"></i>
                    </li>
                `);
            });
        });

        this.containerTarget.innerHTML = content.join('');
    }

    removeTag({ params }) {
        this.selects.forEach((select) => {
            if (select.id !== params.selectId) {
                return;
            }

            const option = select.querySelector(`option[value="${params.value}"]`);
            if (option) {
                option.removeAttribute('selected');
            }

            this.computeContainerTags();

            select.dispatchEvent(new CustomEvent('sync-options'));
        });
    }
}
