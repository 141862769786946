import axios from 'axios';

const adsNative = {
    getSiteId: null,
    getPageId: null,
    getFormatId: document.querySelector('[data-adv-native]'),

    init() {
        if (this.getFormatId !== null) {
            this.AdsNativeCall();
        }
    },
    AdsNativeCall() {
        this.getSiteId = Number(document.querySelector('[data-adv-siteid]').getAttribute('data-adv-siteid'));
        this.getPageId = Number(document.querySelector('[data-adv-pageid]').getAttribute('data-adv-pageid'));

        const formatId = Number(this.getFormatId.getAttribute('data-adv-formatid'));
        const params = {
            siteId: this.getSiteId,
            pageId: this.getPageId,
            formatId: formatId,
            target: null,
        };

        const timestamp = Math.floor(Math.random() * 1000000);
        const clickTrackUrl = encodeURIComponent('');

        // eslint-disable-next-line max-len
        const url = `https://www3.smartadserver.com/ac?out=json&nwid=250&siteid=${params.siteId}&pgid=${params.pageId}&fmtid=${params.formatId}&tgt=${params.target}&visit=m&tmstp=${timestamp}&clcturl=${clickTrackUrl}`;
        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        })
            .then((response) => {
                if (!response.data.length > 0) {
                    // eslint-disable-next-line no-console
                    console.error('adv', 'Err: No native ad were found with the following parameters', params);
                    return false;
                }

                // eslint-disable-next-line no-console
                console.log('adv', 'Rendering native Ad', params);

                this.insertAt(response.data);

                return true;
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    },
    insertAt(data) {
        const target = document.querySelector('[data-adv-native]');
        // eslint-disable-next-line max-len
        target.innerHTML = `<div class="tw-w-full tw-rounded-large tw-bg-indigo-200 tw-px-4 tw-pt-6 tw-pb-5 hover:tw-shadow-lg focus:tw-shadow-lg tw-transition tw-ease-in-out tw-duration-200 hover:tw-transition hover:tw-ease-in-out hover:tw-duration-200 focus:tw-transition focus:tw-ease-in-out tw-duration-200">
            <a href="${data.clickUrlImage_1}" target="${data.target}" class="tw-block tw-text-white">
                <span class="tw-block tw-font-heading tw-leading-6 tw-text-2xl tw-mb-4">${data.userText_1}</span>
                <span class="tw-block tw-mb-[28px]">${data.userText_2}</span>
                <div class="tw-relative tw-flex tw-justify-between tw-items-center">
                    <span class="tw-block">En savoir plus</span>
                    <figure class="tw-min-w-12 tw-w-12 tw-h-12 tw-overflow-hidden">
                        <img src="${data.urlImage_1}" alt=""class=" tw-object-cover tw-w-12 tw-overflow-hidden" />
                    </figure>
                </div>
            </a>
        </div>
        `;
    },
};

export default adsNative.init();
